import { Auth0Client } from '@auth0/auth0-spa-js'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

import { AuthProvider } from '@redwoodjs/auth'
import { RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import './style/index.scss'

const auth0 = new Auth0Client({
  domain: process.env.AUTH0_DOMAIN,
  client_id: process.env.AUTH0_CLIENT_ID,
  redirect_uri: process.env.AUTH0_REDIRECT_URI,

  // ** NOTE ** Storing tokens in browser local storage provides persistence across page refreshes and browser tabs.
  // However, if an attacker can achieve running JavaScript in the SPA using a cross-site scripting (XSS) attack,
  // they can retrieve the tokens stored in local storage.
  // https://auth0.com/docs/libraries/auth0-spa-js#change-storage-options
  cacheLocation: 'localstorage',
  audience: process.env.AUTH0_AUDIENCE,

  // @MARK: useRefreshTokens is required for automatically extending sessions
  // beyond that set in the initial JWT expiration.
  //
  // @MARK: https://auth0.com/docs/tokens/refresh-tokens
  // useRefreshTokens: true,
})

if (process.env.LOGROCKET_PROJECT) {
  LogRocket.init(process.env.LOGROCKET_PROJECT, {
    dom: {
      textSanitizer: true,
    },
  })
}
setupLogRocketReact(LogRocket)
if (process.env.SENTRY_BROWSER_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_BROWSER_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.APP_ENVIRONMENT,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  })
  Sentry.configureScope((scope) => {
    scope.setExtra('sessionURL', LogRocket?.sessionURL || null)
  })
}

const App = () => (
  <Sentry.ErrorBoundary fallback={<FatalErrorPage />} showDialog>
    <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
      <AuthProvider client={auth0} type="auth0">
        <RedwoodApolloProvider>
          <Routes />
        </RedwoodApolloProvider>
      </AuthProvider>
    </RedwoodProvider>
  </Sentry.ErrorBoundary>
)

export default App
