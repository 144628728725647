import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

const LoadingScreen = () => {
  return (
    <div className="flex h-screen	items-center">
      <LoadingSpinner />
    </div>
  )
}

export default LoadingScreen
