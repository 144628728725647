import { Disclosure } from '@headlessui/react'

import { NavLink, routes } from '@redwoodjs/router'

import ProfileAvatar from 'src/components/ProfileAvatar/ProfileAvatar'
import { hasProfile } from 'src/lib/utils'

const NavbarProfileMenuMobile = ({
  currentUser,
  email,
  loading,
  onSignout,
}) => {
  if (loading) {
    return null
  }

  return (
    <div className="border-t border-gray-200 pt-4 pb-3">
      <div className="flex items-center px-5">
        <div className="flex-shrink-0">
          <ProfileAvatar
            name={
              hasProfile(currentUser)
                ? currentUser.profile.givenName +
                  ' ' +
                  currentUser.profile.familyName
                : email
            }
            type="person"
            url={hasProfile(currentUser) && currentUser.profile.profilePhotoUrl}
            size="sm"
            rounded
          />
        </div>
        <div className="ml-3">
          {hasProfile(currentUser) && (
            <div className="text-base font-medium text-gray-700">
              {currentUser.profile.givenName +
                ' ' +
                currentUser.profile.familyName}
            </div>
          )}
          <div className="text-sm font-light text-gray-400">
            {hasProfile(currentUser) ? currentUser.profile.email : email}
          </div>
        </div>
      </div>
      <div className="mt-3 space-y-1 px-2">
        {hasProfile(currentUser) && (
          <>
            <Disclosure.Button
              as={NavLink}
              className={
                'block rounded-md py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-800'
              }
              to={routes.ownUserProfile()}
              activeClassName="bg-violet-50 text-violet-700"
            >
              Your Profile
            </Disclosure.Button>
            <Disclosure.Button
              as={NavLink}
              className={
                'block rounded-md py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-800'
              }
              to={routes.editOwnUserProfile()}
              activeClassName="bg-violet-50 text-violet-700"
            >
              Edit Profile
            </Disclosure.Button>
          </>
        )}
        <Disclosure.Button
          as="button"
          className="block px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50"
          onClick={onSignout}
        >
          Sign out
        </Disclosure.Button>
      </div>
    </div>
  )
}

export default NavbarProfileMenuMobile
