import { Fragment } from 'react'

import { Menu, Transition } from '@headlessui/react'

import { routes, NavLink } from '@redwoodjs/router'

import ProfileAvatar from 'src/components/ProfileAvatar/ProfileAvatar'
import { hasProfile } from 'src/lib/utils'

const NavbarProfileMenuDesktop = ({
  currentUser,
  email,
  loading,
  onSignout,
}) => {
  if (loading) {
    return null
  }

  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-400 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-400">
          <span className="sr-only">Open user menu</span>
          <ProfileAvatar
            name={
              hasProfile(currentUser)
                ? currentUser.profile.givenName +
                  ' ' +
                  currentUser.profile.familyName
                : email
            }
            type="person"
            url={hasProfile(currentUser) && currentUser.profile.profilePhotoUrl}
            size="sm"
            rounded
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {hasProfile(currentUser) && (
            <>
              <Menu.Item>
                <NavLink
                  className="block px-4 py-2 text-sm text-gray-700"
                  activeClassName="bg-gray-100 text-gray-900 hover:bg-gray-100"
                  to={routes.ownUserProfile()}
                >
                  View Profile
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  className="block px-4 py-2 text-sm text-gray-700"
                  activeClassName="bg-gray-100 text-gray-900 hover:bg-gray-100"
                  to={routes.editOwnUserProfile()}
                >
                  Edit Profile
                </NavLink>
              </Menu.Item>
            </>
          )}
          <Menu.Item>
            <button
              className="block px-4 py-2 text-sm text-gray-700"
              onClick={onSignout}
            >
              Sign out
            </button>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default NavbarProfileMenuDesktop
