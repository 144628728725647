// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, Set } from '@redwoodjs/router'

import LoadingScreen from './components/Loading/LoadingScreen/LoadingScreen'
import AppLayout from './layouts/AppLayout/AppLayout'

const Routes = () => {
  return (
    <Router>
      {/* Unauthenticated/Public Pages */}
      <Set wrap={AppLayout} whileLoadingAuth={LoadingScreen}>
        {/* View invite */}
        <Route path="/r/{slug:String}" page={PublicJobListingReferralRedirectPage} name="jobListingReferralRedirect" />
        {/* View individual job */}
        <Route path="/jobs/{id:Int}" page={PublicJobListingPage} name="jobListing" />
      </Set>
      {/* Shared Pages*/}
      <Set wrap={AppLayout} private unauthenticated="login" whileLoadingAuth={LoadingScreen}>
        {/* View all jobs */}
        <Route path="/jobs" page={SharedJobListingsPage} name="jobListings" />
        {/* View all jobs */}
        <Route path="/companies" page={SharedCompanyProfilesPage} name="companyProfiles" />
        {/* View company profile */}
        <Route path="/companies/{id:Int}" page={SharedCompanyProfilePage} name="companyProfile" />
        {/* Messages page - company/jobseeker */}
        <Route path="/messages" page={SharedMessagesPage} name="messages" />
        <Route path="/setup" page={SharedNewUserProfilePage} name="newUserProfile" />
        <Route path="/profile/settings" page={SharedEditUserProfilePage} name="editOwnUserProfile" />
        <Route path="/profile/{id:Int}/settings" page={SharedEditUserProfilePage} name="editOtherUserProfile" />
        <Route path="/profile/{id:Int}" page={SharedUserProfilePage} name="otherUserProfile" />
        <Route path="/profile" page={SharedUserProfilePage} name="ownUserProfile" />
      </Set>
      {/* Admin Pages */}
      <Set wrap={AppLayout} private unauthenticated="login" whileLoadingAuth={LoadingScreen} roles="admin">
        {/* Admin dashboard */}
        <Route path="/admin" page={AdminAdminDashboardPage} name="adminDashboard" />
        {/* Manage all users */}
        <Route path="/admin/users" page={AdminAdminUsersPage} name="adminUsers" />
        {/* Manage all companies */}
        <Route path="/admin/companies" page={AdminAdminCompaniesPage} name="adminCompanies" />
        {/* Add new company */}
        <Route path="/admin/companies/new" page={AdminAdminCompaniesCreatePage} name="adminCompaniesCreate" />
        {/* Edit a company */}
        <Route path="/admin/companies/{id:Int}/settings" page={AdminAdminCompaniesEditPage} name="adminCompaniesEdit" />
        {/* View all invites created */}
        <Route path="/admin/invites" page={AdminAdminReferralsPage} name="adminReferrals" />
        {/* Manage all jobs */}
        <Route path="/admin/jobs" page={AdminAdminJobsPage} name="adminJobs" role />
        {/* Add new job */}
        <Route path="/admin/jobs/new" page={AdminAdminJobsCreatePage} name="adminJobsCreate" />
        {/* Edit a job */}
        <Route path="/admin/jobs/{id:Int}/settings" page={AdminAdminJobsEditPage} name="adminJobsEdit" />
        {/* Manage all job functions */}
        <Route path="/admin/job-functions" page={AdminAdminJobListingFunctionsPage} name="adminJobListingFunctions" />
        {/* Manage all job types */}
        <Route path="/admin/job-types" page={AdminAdminJobListingTypesPage} name="adminJobListingTypes" />
        {/* All rewards earned */}
        <Route path="/admin/rewards" page={AdminAdminRewardsPage} name="adminRewards" />
      </Set>
      {/* Company pages */}
      <Set wrap={AppLayout} private unauthenticated="login" whileLoadingAuth={LoadingScreen} roles={['companyadmin', 'companyuser']}>
        {/* Company dashboard */}
        <Route path="/company" page={CompanyCompanyPage} name="company" />
        {/* Company settings */}
        <Route path="/company/settings" page={CompanyCompanySettingsPage} name="companySettings" />
        {/* All jobs for company */}
        <Route path="/company/jobs" page={CompanyCompanyJobsPage} name="companyJobs" />
        <Route path="/company/jobs/new" page={CompanyCompanyJobsCreatePage} name="companyJobsCreate" />

        <Route path="/company/jobs/{id:Int}/settings" page={CompanyCompanyJobsEditPage} name="companyJobsEdit" />
        {/* All referrals for company */}
        <Route path="/company/referrals" page={CompanyCompanyReferralsPage} name="companyReferrals" />
        {/* All rewards paid out by a company */}
        <Route path="/company/rewards" page={CompanyCompanyRewardsPage} name="companyRewards" />
      </Set>
      {/* Company pages - Company Admin */}
      <Set wrap={AppLayout} private unauthenticated="login" whileLoadingAuth={LoadingScreen} roles="companyadmin">
        {/* Company billing page */}
        <Route path="/company/billing" page={CompanyCompanyBillingPage} name="companyBilling" />
      </Set>
      {/* Recruiter pages */}
      <Set wrap={AppLayout} private unauthenticated="login" whileLoadingAuth={LoadingScreen} roles="referrer">
        {/* Recruiter dashboard */}
        <Route path="/recruit" page={RecruitRecruitPage} name="recruit" />
        {/* All referrals made by a recruiter */}
        <Route path="/recruit/invites" page={RecruitRecruitReferralsPage} name="recruitReferrals" />
        {/* A referral made by a recruiter */}
        {/* <Route path="/recruit/referrals/{id:Int}" page={RecruitRecruitReferralPage} name="recruitReferral" /> */}
        {/* All people that have accepted an invite from a recruiter */}
        <Route path="/recruit/referrals" page={RecruitRecruitNetworkPage} name="recruitNetwork" />
        {/* All rewards earned by a recruiter */}
        <Route path="/recruit/rewards" page={RecruitRecruitRewardsPage} name="recruitRewards" />
      </Set>

      {/* TO DO - cleanup*/}
      <Set wrap={AppLayout} private unauthenticated="login" whileLoadingAuth={LoadingScreen}>
        <Route path="/job-listing-recommendations/new" page={JobListingRecommendationNewJobListingRecommendationPage} name="newJobListingRecommendation" />
        <Route path="/job-listing-recommendations/{id:Int}/edit" page={JobListingRecommendationEditJobListingRecommendationPage} name="editJobListingRecommendation" />
        <Route path="/job-listing-recommendations/{id:Int}" page={JobListingRecommendationJobListingRecommendationPage} name="jobListingRecommendation" />
        <Route path="/job-listing-recommendations" page={JobListingRecommendationJobListingRecommendationsPage} name="jobListingRecommendations" />
      </Set>
      <Route path="/" page={AuthLoginPage} name="login" />
      <Route path="/callback" page={AuthLoginCallbackPage} name="loginCallback" />
      <Route path="/logout" page={AuthLogoutPage} name="logout" />
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
