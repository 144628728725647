import { NavLink } from '@redwoodjs/router'

const NavbarNavigationDesktop = ({ navigation }) => {
  return (
    <div className="ml-10 flex items-baseline space-x-4">
      {navigation.map((item) => (
        <NavLink
          key={item.name}
          className="rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
          activeClassName="bg-gray-100 text-gray-900 hover:bg-gray-100"
          to={item.href}
        >
          {item.name}
        </NavLink>
      ))}
    </div>
  )
}

export default NavbarNavigationDesktop
