const AppFooter = ({ className }) => {
  return (
    <footer className={className}>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className=" py-8 text-center text-sm text-gray-500 sm:text-left">
          <span className="block sm:inline">© 2023 Bonus.xyz, Inc.</span>{' '}
          <span className="block sm:inline">All rights reserved.</span>
        </div>
      </div>
    </footer>
  )
}

export default AppFooter
