// This page will be rendered when an error makes it all the way to the top of the
// application without being handled by a Javascript catch statement or React error
// boundary.
//
// You can modify this page as you wish, but it is important to keep things simple to
// avoid the possibility that it will cause its own error. If it does, Redwood will
// still render a generic error page, but your users will prefer something a bit more
// thoughtful. =)

// Ensures that production builds do not include the error page
/* let RedwoodDevFatalErrorPage = undefined
if (process.env.NODE_ENV === 'development') {
  RedwoodDevFatalErrorPage =
    require('@redwoodjs/web/dist/components/DevFatalErrorPage').DevFatalErrorPage
}
 */
const FatalErrorPage = () => (
  <main className="grid min-h-full place-items-center bg-gray-50 py-24 px-6 sm:py-32 lg:px-8">
    <div className="text-center">
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        Something went wrong
      </h1>
      <p className="mt-6 text-base leading-7 text-gray-600">
        Sorry, an error occured. Our team has been notified of the issue and
        we&apos;re looking into it.
      </p>
    </div>
  </main>
)
export default FatalErrorPage
