import { useEffect } from 'react'

import { setUser } from '@sentry/react'
import LogRocket from 'logrocket'

import { useAuth } from '@redwoodjs/auth'
import { navigate, useLocation, routes } from '@redwoodjs/router'
import { Toaster } from '@redwoodjs/web/toast'

import AppFooter from 'src/components/AppFooter/AppFooter'
import Navbar from 'src/components/Navbar/Navbar'
import { hasProfile, isVerifiedCompanyUser } from 'src/lib/utils'

const AppLayout = ({ children }) => {
  const { currentUser, hasRole, userMetadata } = useAuth()
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname.includes('/setup') && hasProfile(currentUser)) {
      navigate(routes.editOwnUserProfile())
    }
    if (
      ['/company/', '/jobs', '/profile'].some((path) =>
        pathname.includes(path)
      ) &&
      currentUser &&
      hasProfile(currentUser) &&
      hasRole(['companyadmin', 'companyuser']) &&
      !isVerifiedCompanyUser(currentUser.profile)
    ) {
      navigate(routes.company())
    }
    if (
      !['/setup', '/jobs/', '/r/'].some((path) => pathname.includes(path)) &&
      currentUser &&
      !hasProfile(currentUser)
    ) {
      navigate(routes.newUserProfile())
    }
  }, [pathname, currentUser, hasRole])

  useEffect(() => {
    if (currentUser && userMetadata) {
      LogRocket.identify(currentUser.sub, {
        name: currentUser.profile
          ? `${currentUser?.profile?.givenName} ${currentUser?.profile?.familyName}`
          : userMetadata.name,
        email: currentUser?.profile?.email || userMetadata.email,
        profileId: currentUser?.profile?.id,
        role: currentUser?.roles ? currentUser.roles[0] : null,
      })
      setUser({
        username: currentUser.profile
          ? `${currentUser?.profile?.givenName} ${currentUser?.profile?.familyName}`
          : userMetadata.name,
        id: currentUser.sub,
        email: currentUser?.profile?.email || userMetadata.email,
        segment: currentUser?.roles ? currentUser.roles[0] : null,
      })
    }
  }, [currentUser, userMetadata])

  return (
    <>
      <div className="flex min-h-full grow flex-col">
        <Navbar />
        <Toaster
          toastOptions={{
            className: 'rw-toast',
            duration: 6000,
            position: 'bottom-right',
          }}
        />
        {children}
      </div>
      <AppFooter />
    </>
  )
}

export default AppLayout
