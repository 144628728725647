import cleanDeep from 'clean-deep'
import { format, formatDistance } from 'date-fns'

export const isValidUrl = (url) => {
  if (url) {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(url)
  }
  return true
}

export const isValidPhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    const digits = phoneNumber.replace(/\D/g, '')
    const phoneRegex = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/
    return phoneRegex.test(digits)
  }
  return true
}

export const isNotDefaultOption = (value) => {
  if (value == 'Select an option') {
    return false
  }
  return true
}

export const isInArray = (conditionArray, arrayToTest) => {
  return conditionArray.some((matchesCondition) =>
    arrayToTest.includes(matchesCondition)
  )
}

export const timeSince = (date) => {
  /* const randomDate = sub(new Date(date), {
    days: Math.floor(Math.random() * 10),
  }) */
  return formatDistance(new Date(date), new Date(), { addSuffix: true })
}

export const formatDate = (date) => {
  return format(new Date(date), 'MMM d, yyyy')
}

export const formatTime = (datetime) => {
  return format(new Date(datetime), 'h:mm a')
}

export const hasProfile = (user) => {
  return user && user?.profile
}

export const hasCompanyRole = (userProfile) => {
  return isInArray(['companyadmin', 'companyuser'], userProfile.role)
}

export const isVerifiedCompanyUser = (userProfile) => {
  return (
    userProfile && hasCompanyRole(userProfile) && userProfile.companyProfile
  )
}

export const isCompanyOnboardedToStripe = (userProfile) => {
  return (
    isVerifiedCompanyUser(userProfile) &&
    userProfile.companyProfile.hasPaymentMethods
  )
}

export const getMessageUserId = (userProfile) => {
  return isVerifiedCompanyUser(userProfile)
    ? `company-${userProfile.companyProfileId}`
    : `user-${userProfile.id}`
}

export const getOtherMessageUser = (channelMembers, currentUserProfile) => {
  return Object.entries(channelMembers).filter(
    (key) => key[0] !== getMessageUserId(currentUserProfile)
  )[0][1]?.user
}

export const formatCandidateStatus = (status) => {
  switch (status) {
    case 'active':
      return 'Actively looking'
    case 'passive':
      return 'Passively looking'
    default:
      return 'Closed to new roles'
  }
}

export const formatApplicationStatus = (status) => {
  switch (status) {
    case 'hired':
      return 'Hired'
    case 'declined':
      return 'Rejected'
    case 'started':
      return 'In Progress'
    default:
      return 'Pending Review'
  }
}

export const formatRewardType = (type) => {
  switch (type) {
    case 'hired':
      return 'Candidate Hired'
    default:
      return 'Candidate Contacted'
  }
}

export const sanitizeFormValues = (formValues) => {
  return cleanDeep(formValues, {
    cleanValues: ['Select an option'],
    NaNValues: true,
  })
}

export const sortArrayByKey = (array, key) => {
  return [...array].sort(function (a, b) {
    if (a[key] < b[key]) {
      return -1
    }
    if (a[key] > b[key]) {
      return 1
    }
    return 0
  })
}
