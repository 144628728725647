import { useState } from 'react'

import classNames from 'classnames'

const ProfileAvatar = ({
  name,
  type = 'company',
  url,
  size = 'lg',
  rounded,
}) => {
  const [showFallbackImage, setShowFallbackImage] = useState(false)

  const fallbackImage = () => {
    setShowFallbackImage(true)
  }
  const getProfileColorFromName = (string) => {
    const letter = Array.from(
      string
        .split(' ')
        .map((n) => n[0])
        .join('')
        .toLowerCase()
    )[0]
    const colorDictionary = {
      amber: 'abcde',
      sky: 'fghig',
      violet: 'jklmno',
      teal: 'pqrstu',
      rose: 'vwxyz',
    }
    const profileColor =
      Object.entries(colorDictionary)[
        Object.values(colorDictionary).findIndex((letters) =>
          letters.includes(letter)
        )
      ][0] || 'gray'
    return profileColor
  }
  return (
    <div
      className={classNames(
        'align-center flex flex-shrink-0 justify-center overflow-hidden',
        {
          'h-24 w-24 shadow-lg ring-2 ring-white': size === 'lg',
          'h-14 w-14 shadow-md ring-2 ring-white': size === 'md',
          'h-8 w-8 shadow-sm ring-1 ring-white': size === 'sm',
          'h-6 w-6 shadow-sm ring-1 ring-white': size === 'xs',
          'bg-violet-100 text-violet-700':
            (showFallbackImage || !url) &&
            getProfileColorFromName(name) === 'violet',
          'bg-sky-100 text-sky-700':
            (showFallbackImage || !url) &&
            getProfileColorFromName(name) === 'sky',
          'bg-amber-100 text-amber-700':
            (showFallbackImage || !url) &&
            getProfileColorFromName(name) === 'amber',
          'bg-teal-100 text-teal-700':
            (showFallbackImage || !url) &&
            getProfileColorFromName(name) === 'teal',
          'bg-rose-100 text-rose-700':
            (showFallbackImage || !url) &&
            getProfileColorFromName(name) === 'rose',
          'bg-white ': url && !showFallbackImage,
          'rounded-full': rounded,
          'rounded-md': !rounded,
        }
      )}
    >
      {showFallbackImage || !url ? (
        <span className="inline-flex min-h-full min-w-full flex-shrink-0 items-center justify-center">
          <span
            className={classNames('font-medium leading-none', {
              'text-2xl': size === 'lg',
              'text-xl': size === 'md',
              'text-sm': size === 'sm',
              'text-xs': size === 'xs',
            })}
          >
            {name
              .split(' ')
              .map((n) => n[0])
              .join('')}
          </span>
        </span>
      ) : (
        <img
          className="min-h-full min-w-full flex-shrink-0"
          src={
            type === 'company'
              ? `https://logo.clearbit.com/${url}?format=png`
              : url
          }
          alt={name}
          onError={fallbackImage}
        />
      )}
    </div>
  )
}

export default ProfileAvatar
