import { Disclosure } from '@headlessui/react'

import { NavLink } from '@redwoodjs/router'

const NavbarNavigationMobile = ({ navigation }) => {
  return (
    <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
      {navigation.map((item) => (
        <Disclosure.Button
          key={item.name}
          as={NavLink}
          className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
          activeClassName="bg-gray-100 text-gray-900 hover:bg-gray-100"
          to={item.href}
        >
          {item.name}
        </Disclosure.Button>
      ))}
    </div>
  )
}

export default NavbarNavigationMobile
