import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

import { useAuth } from '@redwoodjs/auth'
import { Link, routes } from '@redwoodjs/router'

import useLocalStorage from 'src/lib/hooks/useLocalStorage'
import { hasProfile, isVerifiedCompanyUser } from 'src/lib/utils'

import NavbarNavigationDesktop from './NavbarNavigationDesktop/NavbarNavigationDesktop'
import NavbarNavigationMobile from './NavbarNavigationMobile/NavbarNavigationMobile'
import NavbarProfileMenuDesktop from './NavbarProfileMenuDesktop/NavbarProfileMenuDesktop'
import NavbarProfileMenuMobile from './NavbarProfileMenuMobile/NavbarProfileMenuMobile'

const navigation = {
  referrer: [
    { name: 'Dashboard', href: '/recruit' },
    { name: 'Open Roles', href: '/jobs' },
    { name: 'Invites', href: '/recruit/invites' },
    { name: 'My Referrals', href: '/recruit/referrals' },
    { name: 'Rewards', href: '/recruit/rewards' },
  ],
  jobseeker: [
    { name: 'Profile', href: '/profile' },
    { name: 'Job Board', href: '/jobs' },
    { name: 'Messages', href: '/messages' },
  ],
  companyadmin: [
    { name: 'Dashboard', href: '/company' },
    { name: 'Jobs', href: '/company/jobs' },
    { name: 'Referrals', href: '/company/referrals' },
    { name: 'Messages', href: '/messages' },
    { name: 'Rewards', href: '/company/rewards' },
    { name: 'Billing', href: '/company/billing' },
  ],
  companyuser: [
    { name: 'Dashboard', href: '/company' },
    { name: 'Jobs', href: '/company/jobs' },
    { name: 'Referrals', href: '/company/referrals' },
    { name: 'Messages', href: '/messages' },
    { name: 'Rewards', href: '/company/rewards' },
  ],
  admin: [
    { name: 'Dashboard', href: '/admin' },
    { name: 'Companies', href: '/admin/companies' },
    { name: 'Jobs', href: '/admin/jobs' },
    { name: 'Users', href: '/admin/users' },
    { name: 'Invites', href: '/admin/invites' },
    { name: 'Rewards', href: '/admin/rewards' },
    { name: 'Messages', href: '/messages' },
    /* { name: 'Payouts', href: '/admin/rewards/payouts' }, */
  ],
}

const Navbar = () => {
  const {
    currentUser,
    userMetadata,
    loading,
    isAuthenticated,
    hasRole,
    logOut,
  } = useAuth()
  const [_, setRedirectTo] = useLocalStorage('redirectTo')

  const handleLogout = async () => {
    if (isAuthenticated) {
      setRedirectTo(null)
      await logOut({
        federated: true,
      })
    }
  }
  const showNavMenu =
    isAuthenticated &&
    hasProfile(currentUser) &&
    (hasRole(['referrer', 'admin', 'jobseeker']) ||
      isVerifiedCompanyUser(currentUser.profile))
  return (
    <>
      <Disclosure as="nav" className="border-b border-gray-200 bg-white">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Link to={routes.login()}>
                      <img className="h-8" src="/bonus-logo.svg" alt="Bonus" />
                    </Link>
                  </div>
                  <div className="hidden md:block">
                    {showNavMenu && (
                      <NavbarNavigationDesktop
                        navigation={navigation[currentUser.roles[0]]}
                      />
                    )}
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    {isAuthenticated && (
                      <NavbarProfileMenuDesktop
                        currentUser={currentUser}
                        email={userMetadata?.email}
                        loading={loading}
                        onSignout={handleLogout}
                      />
                    )}
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="focus:ring-gray inline-flex items-center justify-center rounded-md bg-gray-200 p-2 text-gray-700 hover:bg-gray-300 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-400">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            {isAuthenticated && (
              <Disclosure.Panel className="md:hidden">
                {showNavMenu && (
                  <NavbarNavigationMobile
                    navigation={navigation[currentUser.roles[0]]}
                  />
                )}
                <NavbarProfileMenuMobile
                  currentUser={currentUser}
                  email={userMetadata?.email}
                  loading={loading}
                  onSignout={handleLogout}
                />
              </Disclosure.Panel>
            )}
          </>
        )}
      </Disclosure>
    </>
  )
}

export default Navbar
